import React from "react";
import { useActiveHash } from "@hooks/use-active-hash";
import { TableOfContentsWrapper, TOCHeader } from "@styles/blogpost";

export const TableOfContents = ({ data, sections }) => {
  const activeHash = useActiveHash(sections);
  const TOCItem = i => (
    <li
      key={i.url}
      className={activeHash === i.url.replace("#", "") ? "active" : ""}
    >
      <a href={i.url} key={i.url}>
        {i.title}
      </a>
      {i.items && <ul>{i.items.map(i => TOCItem(i))}</ul>}
    </li>
  );
  return (
    <TableOfContentsWrapper>
      <TOCHeader>Table of Contents</TOCHeader>
      <ul>{data.items.map(i => TOCItem(i))}</ul>
    </TableOfContentsWrapper>
  );
};
