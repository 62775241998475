import styled from "styled-components";
import { device } from "./device";

export const TOCHeader = styled.div`
  color: #b7b5bd;
  font-size: 1rem;
  /* margin-top: 2rem; */
  letter-spacing: 0.075em;
  text-transform: uppercase;
  font-weight: bold;
  color: rgb(100, 255, 218);
`;

export const TableOfContentsWrapper = styled.nav`
  background: rgb(23, 42, 69);
  padding: 1.25rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  }

  @media ${device.mobileS} {
    font-size: 1rem;
  }

  @media ${device.desktop} {
    /* position: sticky; */
    position: fixed;
    left: calc(50% + 400px);
    top: 2;
  }

  ul {
    list-style: none;
    margin: 0rem;
    padding: 0rem;
  }
  .active {
    > a {
      border-bottom: 1px solid white;
      color: rgb(100, 255, 218);
      font-weight: 400;
    }
  }
  li {
    color: rgb(215, 218, 220);
    /* line-height: 1.75rem; */
    list-style: none;
    margin: 0rem;
    margin-top: calc(1.5rem / 2);
    ul {
      margin-left: 1.25rem;
    }
    a {
      transition: all 1000ms cubic-bezier(0.165, 0.84, 0.44, 1);
      :hover {
        border-bottom: 1px solid white;
        color: rgb(100, 255, 218);
        font-weight: 400;
      }
      border: none;
      padding-bottom: 0.2rem;
      text-decoration: none;
      color: inherit;
    }
  }
`;

export const PostWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  scroll-behavior: smooth;
  padding: 10px;
  color: #d7dadc;
  font-family: "Karla", serif;
  font-size: 1.15rem;
  /* background-color: black; */
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  a {
    font-weight: 400;
    color: rgb(100, 255, 218);
    border-bottom: 1px solid white;
    text-decoration: none;
  }
  h1 {
    @media ${device.mobileS} {
      font-size: 1.75rem;
    }
    @media ${device.laptopL} {
      font-size: 2rem;
    }
    /* color: #25bfbf; */
  }
  h2 {
    @media ${device.mobileS} {
      font-size: 1.5rem;
    }
    @media ${device.laptopL} {
      font-size: 1.75rem;
    }
  }
  h3 {
    @media ${device.mobileS} {
      font-size: 1.25rem;
    }
    @media ${device.laptopL} {
      font-size: 1.5rem;
    }
  }
  h4 {
    @media ${device.mobileS} {
      font-size: 1rem;
    }
    @media ${device.laptopL} {
      font-size: 1.25rem;
    }
  }
  p {
    color: #cacdcf;
    /* color: rgb(0, 0, 0); */
  }
  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  table {
    margin: 0 auto;
  }
  table,
  th,
  td {
    border: 2px solid rgb(100, 255, 218, 0.25);
    border-collapse: collapse;
  }
  th {
    padding: 10px;
    font-weight: bold;
  }
  td {
    padding: 5px 10px;
  }
  /* .body {
    h1 {
      color: #25bfbf;
    }
    a {
      color: red;
    }
    p {
      font-size: 16pt;
      font-weight: lighter;
    }
    table {
      margin: 0 auto;
    }
    table,
    th,
    td {
      border: 2px solid white;
      border-collapse: collapse;
    }
    th {
      padding: 10px;
      font-weight: bold;
    }
    td {
      padding: 5px;
    }
    img {
      width: 25%;
    }
    .toc {
      background-color: red;
    }
  } */
`;

export const PostTitle = styled.div`
  font-weight: bold;
  text-align: center;
  /* color: rgb(204, 214, 246); */
  margin: 0px;
  @media ${device.mobileS} {
    font-size: 1.75rem;
  }
  @media ${device.tablet} {
    font-size: 2.25rem;
  }
  @media ${device.laptopL} {
    font-size: 2.5rem;
  }
`;

export const PostDate = styled.div`
  /* font-size: 24px; */
  text-align: center;
  margin: 10px;
  color: rgba(255, 255, 255, 0.5);
  @media ${device.mobileS} {
    font-size: 1rem;
  }
  @media ${device.laptop} {
    font-size: 1.25rem;
  }
  @media ${device.laptopL} {
    font-size: 1.5rem;
  }
`;
