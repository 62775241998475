import React from "react";
import Layout from "@components/Layout";
import { PostWrapper, PostTitle, PostDate } from "@styles/blogpost";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { DiscussionEmbed } from "disqus-react";
import { TableOfContents } from "./TableOfContents";
import { Helmet } from "react-helmet";

const getSections = items => {
  let result = [];
  items.forEach(i => {
    result.push(i.url.replace("#", ""));
    if (i.items) {
      result = result.concat(getSections(i.items));
    }
  });

  return result;
};

const BlogPost = ({ location, pageContext }) => {
  const { title, date, body, tableOfContents, timeToRead, slug } = pageContext;
  console.log("re-rendering");

  return (
    <Layout location={location}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PostWrapper>
        <PostTitle>{title}</PostTitle>
        <PostDate>
          {new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}{" "}
          • {timeToRead} min read
        </PostDate>
        <TableOfContents
          data={tableOfContents}
          sections={getSections(tableOfContents.items)}
        />
        <MDXRenderer>{body}</MDXRenderer>
        <DiscussionEmbed
          shortname="rohyl"
          config={{
            identifier: slug,
            title: title,
          }}
        />
      </PostWrapper>
    </Layout>
  );
};

export default BlogPost;
